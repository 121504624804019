<template>
  <Header />
  <div
    class="flex flex-column align-items-center my-5 generate-story-container"
  >
    <Panel :pt="ptOptions">
      <div class="flex flex-column md:flex-row">
        <div class="flex flex-row md:flex-column justify-content-end">
          <img :src="storyImage" alt="story-image" :height="325" :width="300" />
        </div>
        <div class="flex flex-row md:flex-column story-text">
          <p>
            We spoke to young Indian women about their initial sexual
            experiences. We learnt that initial sexual experiences end with
            young women feeling insecure, guilty and scared. These emotions
            remain unspoken, and these problems remain unsolved.
            <br />
            <br />
            Will you voice your story?
            <br />
            <br />
            When other women hear their own voice in your story, their courage
            will grow, and the many stories that have been held inside will come
            pouring out.
          </p>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-center story-footer">
          Generate your unspoken story
        </div>
      </template>
    </Panel>
    <i
      v-if="!storyGenerated"
      class="flex mdi mdi-chevron-double-down mt-2 double-down-icon pointer"
      @click="scrollToGenerateStoryChoices"
    />
    <GenerateStoryChoices
      v-if="!storyGenerated"
      @success:generateStory="successGenerateStoryHandler"
    />
    <GeneratedStory v-else />
  </div>
  <Feedback />
</template>

<script lang="ts" setup>
  import {
    Header,
    GenerateStoryChoices,
    Feedback,
    GeneratedStory,
  } from '@/components/Modules/Unspoken';
  import storyImage from '@/assets/images/storyImage.png';
  import Panel from 'primevue/panel';
  import { ref } from 'vue';

  const ptOptions = {
    root: {
      style: {
        backgroundColor: 'transparent',
        border: 'none',
      },
    },
    content: {
      style: {
        padding: '0px',
      },
    },
    footer: {
      style: {
        padding: ' 0px',
      },
    },
  };

  const storyGenerated = ref<boolean>(false);

  const scrollToGenerateStoryChoices = () => {
    const generateStoryChoicesElement = document.getElementById(
      'generate-story-choices'
    );
    generateStoryChoicesElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const successGenerateStoryHandler = () => {
    storyGenerated.value = true;
    setTimeout(() => {
      const generatedStoryElement = document.getElementById('generated-story');
      generatedStoryElement?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };
</script>

<style lang="scss" scoped>
  .story-text {
    color: #272727;
    text-align: left;
    margin-left: 0px;
    font-family: 'Inter', 'sans-serif';
    font-size: 14px;
    line-height: 21px;
  }

  .story-footer {
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    object-fit: fill;
    border-radius: 2px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    display: block;
  }

  .double-down-icon {
    font-size: 64px;
    line-height: 64px;
    color: #0d0d0d;
  }

  @media (min-width: 768px) {
    .story-text {
      font-size: 18px;
      line-height: 28px;
    }

    .story-footer {
      font-size: 36px;
      line-height: 42px;
    }

    .generate-story-container {
      max-width: 940px;
    }
  }
</style>

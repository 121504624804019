<template>
  <div id="header" class="flex justify-content-between align-items-center">
    <img
      class="flex flex-column"
      :src="brewingLogo"
      alt="logo"
      :height="73"
      :width="128"
    />
    <Button
      class="hidden md:flex flex-column write-us-button"
      label="WRITE TO US"
      severity="contrast"
      variant="outlined"
      @click="writeToUsHandler"
    />
    <i
      class="mdi mdi-menu flex flex-column md:hidden menu-icon"
      @click="toggleHandler"
    />
    <Popover ref="popover" :pt="ptOptions">
      <Button
        class="flex flex-column md:hidden write-us-button"
        label="WRITE TO US"
        severity="contrast"
        variant="outlined"
        @click="writeToUsHandler"
      />
    </Popover>
  </div>
</template>

<script lang="ts" setup>
  import brewingLogo from '@/assets/images/brewingLogo.png';
  import Button from 'primevue/button';
  import Popover from 'primevue/popover';

  import { ref } from 'vue';

  const popover = ref(false);

  const ptOptions = ref({
    root: {
      style: {
        backgroundColor: '#ffffff',
      },
    },
  });

  const writeToUsHandler = () => {
    window.open('mailto:team@brewingequality.in');
  };

  const toggleHandler = (event) => {
    popover.value.toggle(event);
  };
</script>

<style lang="scss" scoped>
  .write-us-button {
    font-size: 10px;
    line-height: 14px;
    padding: 4px 8px;
    background-color: transparent;
    color: #0d0d0d !important;
    border-color: #0d0d0d !important;
    font-family: 'Montserrat', 'sans-serif';
    border-radius: 0px;
    letter-spacing: 2px;
  }

  .write-us-button:hover {
    background-color: #0d0d0d !important;
    color: #ffffff !important;
  }

  .menu-icon {
    font-size: 24px;
    line-height: 24px;
    color: #000000;
  }

  @media (min-width: 768px) {
    .write-us-button {
      font-size: 12px;
      line-height: 20px;
      padding: 12px 25px;
    }
  }
</style>

import { createRouter, createWebHistory } from 'vue-router';
import { Unspoken } from '@/views/Modules/Unspoken';
import { Home } from '@/views/Modules/Home';
import { AllStories } from '@/components/Modules/Unspoken';
const routes = [
  { path: '/', component: Home },
  { path: '/unspoken', component: Unspoken },
  { path: '/unspoken-stories', component: AllStories },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

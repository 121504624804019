<template>
  <div id="generate-story-choices" class="my-5 content">
    <div class="flex justify-content-center story-choices-title py-2">
      Which of the following experiences do you resonate with?
    </div>
    <div class="flex justify-content-center story-choices-description py-2">
      (Tick the boxes to let us know. Generate a card to visualize your
      experience)
    </div>
    <StoryCard
      class="my-5"
      v-for="(option, key) in unspokenOptions"
      :key="key"
      :checkedValue="unspokenStore.state.selectedStories[key]"
      :storyTitle="option.title"
      :storyDescription="option.description"
      :storyImage="option.image"
      @update:checkedValue="onCheckboxChange(key, $event)"
    />
    <div class="flex justify-content-center py-2">
      <Button
        class="generate-button"
        label="GENERATE YOUR UNSPOKEN CARD"
        @click="generateStoryHandler"
        severity="contrast"
        raised
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { unspokenOptions } from '@/constants/modules/Unspoken/common';
  import { StoryCard } from '@/components/Modules/Unspoken';
  import Button from 'primevue/button';
  import { useUnspokenStore } from '@/piniaStore/Modules/Unspoken';

  const emit = defineEmits(['success:generateStory']);

  const unspokenStore = useUnspokenStore();

  const generateStoryHandler = () => {
    const options = Object.keys(unspokenStore.state.selectedStories).filter(
      (key) => unspokenStore.state.selectedStories[key]
    );
    if (options.length) {
      const payload = {
        options: options,
      };
      unspokenStore.saveStory(payload);
    }
    emit('success:generateStory');
  };

  const onCheckboxChange = (key: string, value: boolean) => {
    unspokenStore.state.selectedStories[key] = value;
  };
</script>

<style lang="scss" scoped>
  .content {
    padding-top: 100px;
  }

  .story-choices-title {
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    color: #0d0d0d;
  }
  .story-choices-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #424242;
  }

  .generate-button {
    font-size: 12px;
    line-height: 20px;
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #0d0d0d !important;
    color: #ffffff !important;
    letter-spacing: 2px;
    font-family: 'Montserrat', 'sans-serif';
    border: 1px solid #0d0d0d;
  }

  .generate-button:hover {
    background-color: #313030 !important;
  }

  @media (min-width: 768px) {
    .story-choices-title {
      font-size: 22px;
      line-height: 29px;
    }
    .story-choices-description {
      font-size: 18px;
      line-height: 28px;
    }
  }
</style>

import story1 from '@/assets/images/story1.png';
import story2 from '@/assets/images/story2.png';
import story3 from '@/assets/images/story3.png';
import story4 from '@/assets/images/story4.png';
import story5 from '@/assets/images/story5.png';
import story6 from '@/assets/images/story6.png';
import story7 from '@/assets/images/story7.png';

export const unspokenOptions = {
  story1: {
    title:
      'I felt guilty disappointing my partner or holding the relationship back',
    description:
      'Did you give into sex initiated by your partner as a confirmation of your love towards them? Did you compromise on your own experience for the sake of completing penetration?',
    message:
      "Striking a balance to accommodate for your partner's needs while not compromising on your own, is not always easy. Let's allow ourselves the time and errors it may take to find other ways to express love and commitment.",
    image: story1,
  },
  story2: {
    title:
      "I felt inadequate about sex not being as perfect as it's supposed to be",
    description:
      'Did you evaluate your experience against perfect sex? Did you question whether it was the right partner for first sex? Did you accept the pain as a given during first sex?',
    message:
      "I hope you know now that perfect sex is any kind of sex you enjoy while feeling comfortable and safe. Real sex is very different from what’s on screen. It can be messy, sweaty, clumsy, confusing and different for each person. Let's collectively set a new and real benchmark for first sex.",
    image: story2,
  },
  story3: {
    title: 'I was worried about the side effects and reactions inside my body',
    description:
      'Did you worry that the pain or bleeding post sex indicated a problem inside? Were you unsure of how your PCOS or any other treatment would be triggered by an emergency pill?',
    message:
      "It’s okay to be worried about your health and natural to be scared about uncertainty, isn't it? A good way to combat this is to stay informed. The more we know about ourselves the more likely we are to feel in control. Let's make post sex effects common knowledge!",
    image: story3,
  },
  story4: {
    title: 'I was afraid of any chance of my sexual activity being disclosed',
    description:
      'Did you fear someone at the hotel leaking information of your stay? Did you also delay or avoid visiting a doctor to avoid disclosing your sexual activity?',
    message:
      'It shouldn’t be your burden to hide your sexual activities. Your private life is yours to share or not. We may need to mindfully choose the right location or the doctor for now, and can help each other with our experiences. But this must not be the status quo. We need non judgemental services and safer spaces.',
    image: story4,
  },
  story5: {
    title:
      'I was ashamed of losing my self-respect and dignity when telling others',
    description:
      'Did you feel ashamed of having unprotected sex, or even having sex at all? Or did you only feel ashamed when declaring your sexual activity to others?',
    message:
      "We are often shamed for anything that may not seem 'regular'. You would be surprised to know how normal your experience is, if only more of us spoke up in our own circles. And we still can. Sex was more hushed before we came and will be much less hushed after us.",
    image: story5,
  },
  story6: {
    title: 'I was frightened of pregnancy until my periods arrived',
    description:
      'Did you fear pregnancy all the way until your periods arrived? Did the fear linger even after a negative result of a pregnancy test kit? Were you looking to be 100% sure?',
    message:
      "Fearing an unplanned pregnancy is very common after penetrative sex. We've all been there, especially after unprotected sex. Do remember that no matter what the situation, there is always a solution.",
    image: story6,
  },
  story7: {
    title: 'I was overwhelmed by the fear of a positive pregnancy test result',
    description:
      "Did you delay or avoid testing for pregnancy to avoid facing a positive result? Were you afraid you wouldn't be able to manage one?",
    message:
      "No matter what the situation, there is always a solution. No pregnancy test result is actually unmanageable. Sometimes it takes support and shared responsibility to make it manageable. Let's be the people who can provide that support until that support is not needed at all.",
    image: story7,
  },
};

<template>
  <Panel :pt="ptOptions">
    <div class="flex flex-column md:flex-row gap-5 align-items-center">
      <div class="flex flex-row md:flex-column">
        <img class="story-image" :src="storyImage" alt="story-image" />
      </div>
      <div
        class="flex flex-column justify-content-center gap-4 align-items-start"
      >
        <div class="flex story-title align-items-center">
          <Checkbox
            class="mr-3"
            :modelValue="checkedValue"
            @update:modelValue="onCheckboxChange"
            binary
          />
          {{ storyTitle }}
        </div>
        <div class="flex story-description">{{ storyDescription }}</div>
      </div>
    </div>
  </Panel>
</template>

<script lang="ts" setup>
  import Panel from 'primevue/panel';
  import Checkbox from 'primevue/checkbox';

  defineProps({
    checkedValue: {
      type: Boolean,
      required: true,
    },
    storyTitle: {
      type: String,
      required: true,
    },
    storyDescription: {
      type: String,
      required: true,
    },
    storyImage: {
      type: String,
      required: true,
    },
  });

  const emit = defineEmits(['update:checkedValue']);

  const ptOptions = {
    root: {
      style: {
        background: '#ffffff',
        border: 'none',
        borderRadius: '2px',
      },
    },
  };

  const onCheckboxChange = (event: boolean) => {
    emit('update:checkedValue', event);
  };
</script>

<style scoped>
  .story-image {
    width: 100%;
    max-width: 350px;
  }

  .story-description {
    font-size: 14px;
    line-height: 20px;
    color: #0d0d0d;
  }
  .story-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #0d0d0d;
  }

  @media (min-width: 768px) {
    .story-image {
      width: 150px;
    }
    .story-title {
      font-size: 18px;
    }
    .story-description {
      font-size: 16px;
    }
  }
</style>

<template>
  <div
    id="generated-story"
    class="flex flex-column justify-content-center align-items-center my-5 spacing"
  >
    <div class="flex title">Thank you for voicing your story!</div>
    <div class="flex description">
      Ever since joining this movement, we have felt heard. And we have derived
      comfort from our shared experiences. We hope the same for you. Feel free
      to keep this card for yourself or share it with others. If you come across
      someone else's card, do express your solidarity. We express ours.
    </div>

    <div id="panel-content" class="flex flex-column my-4 panel-content">
      <div class="flex unspoken-title">This is my unspoken story.</div>
      <div class="grid my-2 mx-0">
        <div
          class="col-4 md:col-6"
          v-for="option in selectedStories"
          :key="option"
        >
          <img
            class="result-image"
            :src="unspokenOptions[option].image"
            alt="logo"
          />
        </div>
      </div>
      <div class="flex flex-column md:flex-row justify-content-between">
        <div class="flex flex-row md:flex-column help-text">
          Go to brewingequality.in/unspoken to decipher my experience. If my
          story resonates with you, do reach out to me.
        </div>
        <div class="flex flex-row md:flex-column justify-content-end">
          <img class="image-container" :src="brewingLogo" alt="logo" />
        </div>
      </div>
    </div>
    <div
      class="flex flex-column md:flex-row justify-content-between align-items-center gap-4 md:gap-8"
    >
      <div class="flex flex-row md:flex-column">
        <Button
          class="button-style"
          label="SHARE YOUR STORY"
          severity="contrast"
          @click="shareStory"
        />
      </div>
      <div class="flex flex-row md:flex-column">
        <Button
          class="button-style"
          label="VIEW OTHER STORIES"
          severity="contrast"
          @click="viewAllStoriesHandler"
        />
      </div>
    </div>
    <div v-for="option in selectedStories" :key="option" class="mt-4">
      <div class="flex py-1 story-message">
        {{ unspokenOptions[option].message }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import brewingLogo from '@/assets/images/brewingLogo.png';
  import { useUnspokenStore } from '@/piniaStore/Modules/Unspoken';
  import { unspokenOptions } from '@/constants/modules/Unspoken/common';
  import Button from 'primevue/button';
  import { nextTick, computed } from 'vue';
  import html2canvas from 'html2canvas';
  import { useRouter } from 'vue-router';
  const emit = defineEmits(['viewAllStories']);

  const unspokenStore = useUnspokenStore();
  const router = useRouter();

  const selectedStories = computed(() =>
    Object.keys(unspokenStore.state.selectedStories).filter(
      (key) => unspokenStore.state.selectedStories[key]
    )
  );

  const viewAllStoriesHandler = () => {
    router.push('/unspoken-stories');
    nextTick(() => {
      window.scrollTo(0, 0);
    });
  };

  const shareStory = () => {
    const div = document.getElementById('panel-content');
    html2canvas(div, { useCORS: true }).then((canvas) => {
      var myImage = canvas.toDataURL();
      downloadURI(myImage, 'Unspoken.png');
    });
  };

  const downloadURI = async (uri: string, name: string) => {
    const data = await fetch(uri);
    const blob = await data.blob();
    const file = new File([blob], name, { type: blob.type });
    const files = [file];
    if (navigator.canShare && navigator.canShare({ files })) {
      navigator.share({
        title: 'Unspoken',
        text: 'Check out my #unspoken card!',
        files: files,
      });
    } else {
      var link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
    }
  };
</script>

<style lang="scss" scoped>
  .title {
    font-weight: 600;
    font-size: 33px;
    line-height: 41px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #0d0d0d;
  }

  .description {
    font-size: 14px;
    color: #0d0d0d;
  }

  .story-message {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #0d0d0d;
  }

  .help-text {
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .unspoken-title {
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .image-container {
    width: 45px;
  }

  .result-image {
    width: 100%;
    max-width: 350px;
  }

  .button-style {
    font-size: 12px;
    line-height: 20px;
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #0d0d0d !important;
    color: #ffffff !important;
    font-family: 'Montserrat', 'sans-serif' !important;
    letter-spacing: 2px;
  }

  .button-style:hover {
    background-color: #313030 !important;
  }

  .panel-content {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }

  @media (min-width: 768px) {
    .help-text {
      font-size: 18px;
      line-height: 27px;
    }
    .image-container {
      width: 142px;
    }

    .unspoken-title {
      font-size: 18px;
      line-height: 27px;
    }

    .spacing {
      padding-left: 95px;
      padding-right: 95px;
      margin-top: 200px !important;
    }

    .help-text {
      width: 60%;
    }
  }
</style>

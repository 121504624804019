<template>
  <div class="flex flex-column gap-2 py-5">
    <div class="flex feedback-title">
      How might we can address these unspoken fears?
    </div>
    <InputText
      class="feedback-input"
      :modelValue="feedbackText"
      placeholder="We may ..."
      @update:modelValue="feedbackText = $event"
    />
    <div class="flex py-2">
      <Button
        class="feedback-button"
        label="Submit"
        severity="contrast"
        @click="saveFeedbackHandler"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import { type Ref, ref } from 'vue';
  import { useUnspokenStore } from '@/piniaStore/Modules/Unspoken';

  const unspokenStore = useUnspokenStore();

  const feedbackText: Ref<string> = ref<string>('');

  const saveFeedbackHandler = async () => {
    const payload = {
      feedback: feedbackText.value,
    };
    await unspokenStore.saveFeedback(payload);
    feedbackText.value = '';
  };
</script>

<style lang="scss" scoped>
  .feedback-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0d0d0d;
  }

  .feedback-input {
    background: #ffffff;
    color: #0d0d0d;
  }

  .feedback-button {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 20px;
    border-radius: 0px;
    background-color: #0d0d0d !important;
    color: #ffffff !important;
  }

  @media (min-width: 768px) {
    .feedback-title {
      font-size: 22px;
      line-height: 33px;
    }
  }
</style>

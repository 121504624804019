import { unspokenOptions } from '@/constants/modules/Unspoken/common';
import axios from 'axios';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useUnspokenStore = defineStore('unspoken', () => {
  const state = reactive({
    selectedStories: Object.keys(unspokenOptions).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    ) as Record<string, boolean>,
  });

  const getAllStories = async () => {
    const response = await axios.get(
      'https://ohd3lgfkl6.execute-api.ap-south-1.amazonaws.com/Prod/get-stories'
    );
    return response.data.stories;
  };

  const saveStory = async (payload: any) => {
    await axios.post(
      'https://ohd3lgfkl6.execute-api.ap-south-1.amazonaws.com/Prod/save-story',
      payload
    );
  };

  const saveFeedback = async (payload: any) => {
    await axios.post(
      'https://ohd3lgfkl6.execute-api.ap-south-1.amazonaws.com/Prod/save-feedback',
      payload
    );
  };

  return {
    state,
    getAllStories,
    saveStory,
    saveFeedback,
  };
});

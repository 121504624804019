<template>
  <Header />
  <div class="flex flex-column md:flex-row justify-content-between py-8">
    <div class="flex flex-column gap-4 w-100 md:w-4 color-style">
      <div class="flex heading">
        How do young women feel after their initial sexual experiences?
      </div>
      <div class="flex text-style">
        The symbols below represent emotions that have remained unspoken for too
        long. Each card forms a story based on a set of questions answered by
        those who visited our site.
      </div>
      <div class="flex">
        <div class="flex flex-column gap-2">
          <div
            class="flex gap-4"
            v-for="(option, key) in unspokenOptions"
            :key="key"
          >
            <img
              class="flex flex-column"
              :src="option.image"
              alt="logo"
              height="69"
              width="69"
            />
            <div class="flex flex-column text-style">
              {{ option.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex text-style">
        By sharing their stories, they explore and highlight the invisible
        nuanced and often emotional aspects of sexual experiences. Through the
        Unspoken movement, we seek to raise questions on the mental health
        outcomes of sexual experiences.
      </div>
      <div class="flex text-style">
        We will continue to collect stories of young women thereby creating an
        ongoing visual record of unspoken experiences.
      </div>
      <div class="flex">
        <Button
          class="button"
          label="GENERATE YOUR STORY"
          severity="contrast"
          @click="generateStoryHandler"
        />
      </div>
    </div>
    <div class="flex-row md:flex-column w-100 md:w-7 my-4 md:my-0">
      <div class="grid m-0 justify-content-between gap-4">
        <StoryImageCard
          class="col col-12 md:col-5"
          v-for="(key, index) in keyList"
          :key="index"
          :keyList="key"
        />
      </div>
    </div>
  </div>
  <Divider />
  <Feedback />
</template>

<script lang="ts" setup>
  import Divider from 'primevue/divider';
  import { Feedback, Header } from '@/components/Modules/Unspoken';
  import { unspokenOptions } from '@/constants/modules/Unspoken/common';
  import StoryImageCard from '@/components/Modules/Unspoken/StoryImageCard.vue';
  import { useUnspokenStore } from '@/piniaStore/Modules/Unspoken';
  import Button from 'primevue/button';
  import { nextTick, onMounted, type Ref, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const unspokenStore = useUnspokenStore();
  const router = useRouter();

  const keyList: Ref<string[][]> = ref([]);

  const generateStoryHandler = () => {
    Object.keys(unspokenStore.state.selectedStories).forEach((key) => {
      unspokenStore.state.selectedStories[key] = false;
    });

    router.push('/unspoken');
    nextTick(() => {
      window.scrollTo(0, 0);
    });
  };

  onMounted(async () => {
    const stories = await unspokenStore.getAllStories();
    stories.forEach((story) => {
      keyList.value.push(story.options);
    });
  });
</script>

<style lang="scss" scoped>
  .text-style {
    font-size: 14px;
    line-height: 20px;
  }

  .button {
    font-size: 12px;
    line-height: 20px;
    padding: 9px 20px;
    border-radius: 0px;
    background-color: #0d0d0d !important;
    color: #ffffff !important;
    font-family: 'Montserrat', 'sans-serif';
    letter-spacing: 2px;
  }

  .button:hover {
    background-color: #313030 !important;
  }

  .heading {
    font-size: 24px;
    line-height: 30px;
    margin-top: 10px;
    font-weight: 600;
  }

  .color-style {
    color: #0d0d0d;
  }
</style>
